<template>
  <div class="mt-5">
    <block-dashboard-wrapper title="Liste d'utilisateurs">
      <template v-slot:button>
        <button
          type="button"
          class="content-wrapper__header-button"
          @click="createUser()"
        >
          Ajouter un utilisateur
        </button>
      </template>
      <block-users-table @updateUser="editUser($event)"></block-users-table>
    </block-dashboard-wrapper>
    <molecule-modal-wrapper :open="isModalOpen" @modalClose="closeModal()">
      <block-users-form
        @modalClose="closeModal()"
        :user="modalUser"
      ></block-users-form>
    </molecule-modal-wrapper>
  </div>
</template>

<script setup>
import BlockDashboardWrapper from "@/components/blocks/BlockDashboardWrapper.vue";
import BlockUsersTable from "@/components/blocks/tables/BlockUsersTable.vue";
import MoleculeModalWrapper from "@/components/molecules/MoleculeModalWrapper.vue";
import BlockUsersForm from "@/components/blocks/forms/BlockUsersForm.vue";
import { ref } from "vue";

// Modal code
const isModalOpen = ref(false);
const isEditMode = ref(false);
const closeModal = () => {
  isModalOpen.value = false;
};
// Modal user code
const modalUser = ref({});
// Create user function
const createUser = () => {
  isModalOpen.value = true;
  modalUser.value = {
    firstname: "",
    lastname: "",
    email: "",
    job: "",
    role: "",
  };
};
// Edit user function
const editUser = (user) => {
  modalUser.value = user;
  isModalOpen.value = true;
};
</script>

<style lang="scss" scoped></style>
