<template>
  <form action="#" method="POST">
    <div class="overflow-hidden">
      <div class="px-2">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 sm:col-span-3">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              >Prénom</label
            >
            <input
              type="text"
              name="first-name"
              id="first-name"
              v-model="user.firstname"
              autocomplete="given-name"
              class="
                mt-1
                focus:ring-primary focus:border-primary
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
              >Nom</label
            >
            <input
              type="text"
              name="last-name"
              id="last-name"
              v-model="user.lastname"
              autocomplete="family-name"
              class="
                mt-1
                focus:ring-primary focus:border-primary
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
          <div class="col-span-6">
            <label
              for="email-address"
              class="block text-sm font-medium text-gray-700"
              >Email</label
            >
            <input
              type="text"
              name="email-address"
              id="email-address"
              v-model="user.email"
              autocomplete="email"
              class="
                mt-1
                focus:ring-primary focus:border-primary
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
          <div class="col-span-6 sm:col-span-3">
            <label for="job" class="block text-sm font-medium text-gray-700"
              >Emploi</label
            >
            <select
              id="job"
              name="job"
              autocomplete="job-name"
              v-model="user.job"
              class="
                mt-1
                block
                w-full
                py-2
                px-3
                border border-gray-300
                bg-white
                rounded-md
                shadow-sm
                focus:outline-none focus:ring-primary focus:border-primary
                sm:text-sm
              "
            >
              <option value="journaliste">Journaliste</option>
              <option value="La société civile">Société civile</option>
              <option value="parlementaire">Parlmenetaire</option>
              <option value="chercheur">Chercheur</option>
            </select>
          </div>
          <div class="col-span-6 sm:col-span-3">
            <label for="country" class="block text-sm font-medium text-gray-700"
              >Role</label
            >
            <select
              id="country"
              name="country"
              autocomplete="country-name"
              v-model="user.role"
              class="
                mt-1
                block
                w-full
                py-2
                px-3
                border border-gray-300
                bg-white
                rounded-md
                shadow-sm
                focus:outline-none focus:ring-primary focus:border-primary
                sm:text-sm
              "
            >
              <option value="admin">Administrateur</option>
              <option value="client">Utilisateur</option>
              <option value="guest">Invité</option>
            </select>
          </div>
          <div class="col-span-6">
            <label for="avatar" class="block text-sm font-medium text-gray-700"
              >Avatar</label
            >
            <div class="flex items-center mt-1">
              <span class="h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                <svg
                  v-show="!selectedImage"
                  class="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
                <img v-show="selectedImage" :src="selectedImage" alt="" />
              </span>
              <label
                for="community-avatar"
                class="
                  ml-5
                  bg-white
                  py-2
                  px-3
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  text-sm
                  leading-4
                  font-medium
                  text-gray-700
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-primary
                "
                >Modifier</label
              >
              <input
                @change="updateSelectedFile"
                type="file"
                hidden
                id="community-avatar"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="py-4 text-right px-2">
        <button
          type="submit"
          class="
            inline-flex
            justify-center
            py-2
            px-4
            border border-transparent
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-white
            bg-primary
            hover:bg-primary
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-primary
          "
          @click.prevent="submitForm()"
        >
          Soumettre
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { toRefs, ref } from "vue";
import {useStore} from "vuex";
const {dispatch} = useStore();
const props = defineProps({
  user: Object,
});
const { user } = toRefs(props);
const emit = defineEmits(['modalClose']);

// Avatar upload
const selectedImage = ref(null);
const selectedFile = ref(null);
const updateSelectedFile = (e) => {
  selectedFile.value = e.target.files;
  selectedImage.value = URL.createObjectURL(selectedFile.value[0]);
};

const submitForm = async () => {
  // Upload user avatar
  if (selectedFile.value?.length) {
    const [{ path }] = await dispatch("files/upload", selectedFile.value);
    // Add avatar to community payload
    user.value.avatar = path;
  }
  // // Update user
  if (user.value.id) {
    try {
      const newUser = await dispatch('users/update', user.value);
      emit('modalClose');
    } catch (err) {
      const {message} = err.response.data;
      console.log(message);
    }
  }
  // // Create user
  else {
    try {
      // Create user 
      await dispatch("users/create", user.value);
      emit('modalClose');
    } catch (err) {
        console.log(err);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>